import React from 'react'

import { navigate } from 'gatsby'
import tw from 'twin.macro'

import { useBottomCta } from 'content-queries/mx/bottom-cta'
import fireEvent from 'context/tracking/events'
import { CaseStudiesPopup } from 'mx/case-studies-popup'
import {
  MxSectionDescription,
  MxSectionTitle,
  WebsiteButton as Button,
} from 'mx/components/section-components'
import { Section } from 'mx/section'
import { FormContactSource } from 'types/crm'
import { resourcesUrls } from 'views/utils'

const BottomCTA = () => {
  const [openPopup, setOpenPopup] = React.useState(false)
  const { data } = useBottomCta()
  const [
    {
      node: {
        meta: {
          secondaryText,
          secondaryTitle,
          tertiaryTitle,
          tertiaryText,
          tertiaryLink,
        },
        actions,
        description,
        title,
        sectionModules,
      },
    },
  ] = data

  return (
    <>
      <Section padding="none" css={[tw`mt-44`]}>
        <div tw="grid grid-cols-16 grid-rows-1">
          <div tw="row-start-1 row-end-1 col-start-1 pl-4 col-end-16 space-y-8 mb-16 xs:(mb-12) md:(py-28 row-start-1 row-end-3 col-start-1 self-center col-end-8) lg:(mb-0 pl-8 space-y-8 row-end-1 col-end-9) xl:(col-end-7 py-16)">
            <MxSectionTitle tw="text-4xl leading-tighter">
              {title}
            </MxSectionTitle>
            <MxSectionDescription>{description}</MxSectionDescription>
            <Button
              onClick={() => {
                fireEvent({
                  type: 'cta_clicked',
                  ctaStr: 'Try Form For Free // Bottom CTA',
                })
                navigate(actions.link)
              }}
              color="transparentBlack"
              rounded
            >
              {actions.text}
            </Button>
          </div>
          <div tw="pl-4 mb-8 col-start-1 col-end-15 self-end flex items-start xs:(col-start-1 col-end-9 mb-0) md:(w-auto mb-12 self-center row-start-1 row-end-1 col-start-9 col-end-15 items-stretch pl-0 flex-col) lg:(self-end col-start-9 col-end-13 pb-0)">
            <div tw="lg:(grid items-center grid-cols-1 grid-rows-1 mb-16)">
              <div tw="z-10 self-center flex justify-center col-start-1 col-end-1 row-start-1 row-end-1">
                <img
                  tw="w-28 h-28 lg:(h-40 w-40)"
                  src={sectionModules[0]?.contentSlots[0]?.photos[0]?.file.url}
                />
              </div>
            </div>
            <div tw="pl-4 space-y-2 md:(space-y-4 pr-12)">
              <p tw="text-2xl leading-tight font-thin text-black max-w-xs md:(text-3xl pr-10)">
                {secondaryTitle}
              </p>
              <Button
                kind="link"
                onClick={() => {
                  setOpenPopup(true)
                }}
              >
                {secondaryText}
              </Button>
            </div>
          </div>
          <div tw="ml-4 col-start-1 col-end-15 self-end flex items-start relative xs:(col-start-9 col-end-16) sm:(ml-0) md:(w-auto items-stretch flex-col row-start-2 row-end-2 col-start-9 col-end-15) lg:(row-start-1 row-end-1 self-start col-start-13 col-end-17 pb-0)">
            <div tw="lg:(grid items-center grid-cols-1 grid-rows-1 mb-16)">
              <div tw="z-10 self-center flex justify-center col-start-1 col-end-1 row-start-1 row-end-1">
                <img
                  tw="w-28 h-28 lg:(h-40 w-40)"
                  src={sectionModules[0]?.contentSlots[0]?.photos[1]?.file.url}
                />
              </div>
            </div>
            <div tw="pl-4 space-y-2 md:(space-y-4 pr-12)">
              <p tw="text-2xl leading-tight font-thin text-black max-w-xs md:(text-3xl pr-10)">
                {tertiaryTitle}
              </p>
              <Button
                kind="link"
                onClick={() => {
                  navigate(tertiaryLink)
                }}
              >
                {tertiaryText}
              </Button>
            </div>
          </div>
        </div>
      </Section>
      <CaseStudiesPopup
        contactSource={FormContactSource.CATALOG}
        onCloseModal={() => {
          setOpenPopup(false)
        }}
        onSubmit={() => {
          setOpenPopup(false)
          window.open(resourcesUrls.caseStudies)
        }}
        openModal={openPopup}
      />
    </>
  )
}

export { BottomCTA }
