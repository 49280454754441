import React, { useEffect } from 'react'

import 'twin.macro'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'

import SEO from 'components/shared/seo'
import useGuarantees from 'content-queries/mx/guarantees'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { BottomCTA } from 'mx/bottom-cta'
import Layout from 'mx/layout'

interface GuaranteeProps {
  slotTitle: string
  textparagraphquote: {
    textparagraphquote: string
  }
  markdown: RenderRichTextData<ContentfulRichTextGatsbyReference>
  photos: {
    file?: {
      url?: string
    }
  }[]
}

const Guarantee = ({ content }: { content: GuaranteeProps }) => {
  const { slotTitle, textparagraphquote, markdown, photos } = content

  return (
    <div tw="max-w-screen-xl bg-gray-500/5 px-4 py-8 m-4 md:(grid grid-cols-2 px-8 py-12 m-0 mx-auto items-stretch) lg:h-128">
      <div tw="flex flex-col h-full justify-between mb-8 md:(mb-0)">
        <p tw="order-2 text-4xl font-thin leading-tighter text-gray-900 md:(max-w-md order-1)">
          {slotTitle}
        </p>
        <img
          tw="mb-8 order-1 w-28 h-auto md:(order-2 w-48 mb-0 relative -left-2 -bottom-4)"
          src={photos?.[0]?.file?.url}
        />
      </div>
      <div tw="space-y-4 flex flex-col justify-between md:(px-4 space-y-0)">
        {renderRichText(markdown, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
              if (node.nodeType === 'paragraph') {
                return (
                  <p tw="text-3xl font-thin leading-snug text-gray-700 md:(mb-4 text-4xl leading-tight text-gray-600)">
                    {children}
                  </p>
                )
              }
            },
          },
        })}

        <p tw="text-lg font-light leading-relaxed text-gray-900 max-w-lg">
          {textparagraphquote?.textparagraphquote}
        </p>
      </div>
    </div>
  )
}

const GuaranteesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Guarantees',
    })
  }, [])
  const { data } = useGuarantees()

  const [
    {
      node: {
        contentSlots,
        images,
        moduleMisc: { description, pre, post },
        moduleTitle,
      },
    },
  ] = data

  return (
    <Layout>
      <AutomaticPopup />
      <div tw="max-w-screen-xl pb-8 md:(mx-4 pb-0 pt-16 mb-16) lg:(mx-8) xl:mx-auto">
        <div tw="overflow-hidden border-b border-gray-400 pb-12 flex flex-col md:(grid grid-cols-2 items-stretch)">
          <div tw="pl-4 order-2 md:(pl-0 mt-16 order-1 justify-between flex flex-col)">
            <div tw="flex flex-col w-full">
              <h1 tw="text-4xl leading-tight font-thin text-gray-800 max-w-xl">
                {moduleTitle}
              </h1>
              <p tw="my-6 font-light text-gray-600 max-w-sm place-self-end leading-relaxed md:(ml-12 pr-2) lg:(ml-24 text-xl pr-0)">
                “{description}”
              </p>
            </div>
            <div tw="place-self-end space-y-1 md:(pr-6)">
              <p tw="capitalize text-base text-gray-600">{pre}</p>
              <p tw="capitalize text-base text-gray-600">{post}</p>
            </div>
          </div>
          <div tw="overflow-hidden order-1 mb-6 w-full md:(order-2 block mb-0)">
            <div tw="-ml-20 md:h-full md:[width:38rem] md:ml-0 lg:[width:110%] xl:w-full">
              <GatsbyImage
                alt="Co-founders of FORM"
                image={images?.[0]?.localFile.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </div>
      <div tw="md:space-y-12">
        {contentSlots.map((guarantee: GuaranteeProps, idx: number) => (
          <Guarantee key={idx} content={guarantee} />
        ))}
      </div>
      <BottomCTA />
    </Layout>
  )
}

export default GuaranteesPage

export const Head = () => (
  <SEO
    description="Explore our guarantees: perfect-fit, on-time delivery, and a 25-year manufacturer’s warranty."
    title="Guarantees"
    path="/guarantees"
  />
)
