import { graphql, useStaticQuery } from 'gatsby'

export const useBottomCta = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "7BF68OxoRRm7UgPBhWoD1J" } }
      ) {
        data: edges {
          node {
            meta {
              secondaryTitle
              secondaryText
              secondaryLink
              tertiaryTitle
              tertiaryText
              tertiaryLink
            }
            title
            description
            actions {
              link
              text
            }
            sectionModules {
              contentSlots {
                photos {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
